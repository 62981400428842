type ProjectOptions = {
  [key: string]: any;
};

export const PROJECT: ProjectOptions = {
  name: '44 React Skeleton App',
  version: 'v0.0.2',
  description: 'forty-four React Skeleton Demo App',
  slogan: 'Start a \nnew project with',
  website: 'forty-four.de',
  website_url: 'https://forty-four.de',
  copyright: '© All rights reserved',
  logo_small: './static/brand/logo_single.svg',
  logo_full: './static/brand/logo_full.svg',
  logo_use_dynamic: true,
  settings_enable: true,
  settings: {
    themeMode: 'dark', // light, dark
    themeDirection: 'ltr',
    themeColor: 'cyan', // blue, cyan, red, purple, orange
    themeStretch: false
  }
};
