/*
 * 44 Adapter Component to default Logo to add support of normal .svg-files
 *
 */

// material
import { Box, BoxProps } from '@mui/material';

import { PROJECT } from './../../project';

import Logo from '../Logo';

// ----------------------------------------------------------------------

export default function FfLogo({ sx }: BoxProps) {
  if (PROJECT.logo_use_dynamic) {
    return <Logo sx={{ ...sx }} />;
  } else {
    return (
      <Box sx={{ width: 40, height: 40, ...sx }}>
        <img src={PROJECT.logo_small} alt="logo" />
      </Box>
    );
  }
}
