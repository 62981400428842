// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/LoadingScreen';
import ThemePrimaryColor from './components/ThemePrimaryColor';

//import { PROJECT } from './project';
//import Show from './components/ff/Show';
import CheckProjectSetting from './components/ff/CheckProjectSetting';
import ProjectProvider from './components/ff/ProjectProvider';
// ----------------------------------------------------------------------

export default function App() {
  return (
    <ProjectProvider>
      <ThemeConfig>
        <ThemePrimaryColor>
          <RtlLayout>
            <GlobalStyles />
            <ProgressBarStyle />
            <CheckProjectSetting attr="settings_enable">
              <Settings />
            </CheckProjectSetting>
            <ScrollToTop />
            <Router />
          </RtlLayout>
        </ThemePrimaryColor>
      </ThemeConfig>
    </ProjectProvider>
  );
}
