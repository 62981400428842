import { ReactNode } from 'react';

import { PROJECT } from './../../project';

// ----------------------------------------------------------------------

type CheckProjectSettingProps = {
  children: ReactNode;
  attr: string;
  /*attribute:
    | 'settings_enable'
    | 'logo_use_dynamic'*/
};

function checkAttr(attr: string): string {
  return PROJECT[attr];
}

export default function CheckProjectSetting({ attr, children }: CheckProjectSettingProps) {
  const res = checkAttr(attr);
  console.debug('⭕ ProjectSetting: ' + attr, res ? '✅' : '❌');

  return res ? <>{children}</> : null;
}
