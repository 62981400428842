import { ReactNode } from 'react';

// contexts
//import { SettingsProvider } from '../../contexts/SettingsContext';

import { PROJECT } from './../../project';
// import useLocalStorage from '../../hooks/useLocalStorage';

// ----------------------------------------------------------------------

function Constructor() {
  console.debug('🚀 Welcome to', PROJECT.name);

  /*const [settings, setSettings] = useLocalStorage('settings', {
    themeMode: 'light',
    themeDirection: 'ltr',
    themeColor: 'default',
    themeStretch: false
  });

  // erzeugt ein infinity-loop!!!! :(
  setSettings({
    themeMode: PROJECT.settings.themeMode, // 'dark',
    themeDirection: PROJECT.settings.themeDirection, // 'ltr',
    themeColor: PROJECT.settings.themeColor, // 'red',
    themeStretch: PROJECT.settings.themeStretch // false
  });*/
}

type ProjectProviderProps = {
  children: ReactNode;
};

export default function ProjectProvider({ children }: ProjectProviderProps) {
  Constructor();

  return <>{children}</>;
}
